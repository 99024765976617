import React from "react";
import { ReactComponent as Facial } from "../svg/faceWax.svg";
import { ReactComponent as UpperBody } from "../svg/upperBodyWax.svg";
import { ReactComponent as LowerBody } from "../svg/lowerBodyWax.svg";
import { ReactComponent as Special } from "../svg/special.svg";

export const facialWaxingItems = {
	logo: {
		title: "Facial Waxing",
		vector: <Facial />,
	},
	forHer: [
		{
			service: "Chin",
			price: "$7",
		},
		{
			service: "Upper Lip",
			price: "$7",
		},
		{
			service: "Side Burns",
			price: "$10",
		},
		{
			service: "Nostrils",
			price: "$10",
		},
		{
			service: "Hairline",
			price: "$10",
		},
		{
			service: "Cheeks",
			price: "$12",
		},
		{
			service: "Eyebrows",
			price: "$15",
		},
		{
			service: "Full Face",
			price: "$25",
		},
	],
	forHim: [
		{
			service: "Ears",
			price: "$12",
		},
		{
			service: "Eyebrows",
			price: "$15",
		},
	],
};

export const upperBodyWaxingItems = {
	logo: {
		title: "Upper Body Waxing",
		vector: <UpperBody />,
	},
	forHer: [
		{
			service: "Neck",
			price: "$10",
		},
		{
			service: "Areolas",
			price: "$10",
		},
		{
			service: "Belly Strip",
			price: "$10",
		},
		{
			service: "Underarm",
			price: "$15",
		},
		{
			service: "Half Back",
			price: "$15",
		},
		{
			service: "Full Back",
			price: "$40",
		},
		{
			service: "Stomach",
			price: "$20",
		},
		{
			service: "Half Arms",
			price: "$25",
		},
		{
			service: "Full Arms",
			price: "$35",
		},
	],
	forHim: [
		{
			service: "Shoulders",
			price: "$15",
		},
		{
			service: "Underarm",
			price: "$15",
		},
		{
			service: "Half Arms",
			price: "$25",
		},
		{
			service: "Full Arms",
			price: "$40",
		},
		{
			service: "Full Back",
			price: "$40",
		},
		{
			service: "Stomach",
			price: "$30",
		},
		{
			service: "Chest",
			price: "$35",
		},
		{
			service: "Chest & Stomach",
			price: "$60",
		},
	],
};

export const lowerBodyWaxingItems = {
	logo: {
		title: "Lower Body Waxing",
		vector: <LowerBody />,
	},
	forHer: [
		{
			service: "Toes",
			price: "$7",
		},
		{
			service: "Feet",
			price: "$7",
		},
		{
			service: "Toes / Feet",
			price: "$12",
		},
		{
			service: "Buttocks",
			price: "$15",
		},
		{
			service: "Inner Thighs",
			price: "$15",
		},
		{
			service: "Half Legs",
			price: "$28",
		},
		{
			service: "Full Legs",
			price: "$50",
		},
		{
			service: "Back Brazilian",
			price: "$15",
		},
		{
			service: "Bikini Line",
			price: "$30",
			span: "bl",
		},
		{
			service: "French",
			price: "$35",
			span: "frn",
		},
		{
			service: "Brazilian",
			price: "$45",
			span: "brz",
		},
	],
	forHim: [
		{
			service: "Buttocks",
			price: "$30",
		},
		{
			service: "Half Legs",
			price: "$30",
		},
		{
			service: "Full Legs",
			price: "$50",
		},
		{
			service: "Back Brazilian",
			price: "$25",
		},
		{
			service: "Bikini Line",
			price: "$40",
		},
		{
			service: "Brazilian",
			price: "$65",
		},
	],
};

export const specialPackagesItems = {
	logo: {
		title: "Special Packages",
		vector: <Special />,
	},
	forHer: [
		{
			service: "Brazilian & Underarm",
			price: "$55",
		},
		{
			service: "Full Body (Upper Lip, Underarm, Brazilian & Full Legs)",
			price: "$75",
		},
	],
	forHim: [
		{
			service: "Brazilian, Full Legs, Chest, Back & Half Arms",
			price: "$175",
		},
	],
};
