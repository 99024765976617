import _ from "lodash";
import "./Carousel.css";
import "../fonts/css/all.css";
import React from "react";
import { carouselItems } from "./carouselItems";
import { Link } from "react-scroll";

class Carousel extends React.Component {
	componentDidMount() {
		(function ($) {
			$(function () {
				// Manually Starting carousel after Link redirect since there is no page load.
				$("#LandingBannerCarousel").carousel();
			});
		})(window.jQuery);
	}

	renderCarouselItemIndicator() {
		return _.map(carouselItems, ({ companyMoto }, i) => {
			return (
				<button
					key={i}
					type="button"
					data-bs-target="#LandingBannerCarousel"
					data-bs-slide-to={`${i}`}
					className={i === 0 ? "active" : null}
					aria-current="true"
					aria-label={`Slide ${i + 1}`}
				></button>
			);
		});
	}

	renderCarouselItems() {
		return _.map(
			carouselItems,
			(
				{
					companyMoto,
					companyName,
					carouselPictureClass,
					serviceMessage,
					button,
				},
				i
			) => {
				return (
					<div key={i} className={`carousel-item ${i === 0 ? "active" : null}`}>
						<div className="carousel-pic">
							<div className={`full-height ${carouselPictureClass}`}></div>
							<div className="intro-pic-overlay">
								<div className="full-width flex-center company-mo-before"></div>
								<div className="col-12 flex-center company-mo">
									{companyMoto}
								</div>
								<div className="col-12 align-center company-name">
									{companyName}
								</div>
								<div className="align-center col-10 offset-1 col-lg-8 offset-lg-2 col-xl-4 offset-xl-4 service-message">
									{serviceMessage}
								</div>
								{button}
								<div className="full-width flex-center service-message-after"></div>
								<div className="col-12 align-center smooth-scroll-btn">
									<Link
										activeClass="active"
										to="slide1"
										spy={true}
										smooth={true}
										duration={500}
										delay={0}
										className="btn btn-circle flex-top"
									>
										<i className="flaticon-down-arrow animated infinite fadeOutDown"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		);
	}

	render() {
		return (
			<div className="col-12 no-pad">
				<div
					id="LandingBannerCarousel"
					className="carousel slide"
					data-bs-ride="carousel"
				>
					<div className="carousel-indicators">
						{this.renderCarouselItemIndicator()}
					</div>
					<div className="carousel-inner">{this.renderCarouselItems()}</div>
					<button
						className="carousel-control-prev"
						type="button"
						data-bs-target="#LandingBannerCarousel"
						data-bs-slide="prev"
					>
						&#8592;
					</button>
					<button
						className="carousel-control-next"
						type="button"
						data-bs-target="#LandingBannerCarousel"
						data-bs-slide="next"
					>
						&#8594;
					</button>
				</div>
			</div>
		);
	}
}

export default Carousel;
