export const feedbackFields = [
	{
		icon: "user form-user",
		label: "Full name",
		name: "fullname",
	},
	{ icon: "envelope form-envelope", label: "Email address", name: "email" },
	{
		icon: "pen form-pen",
		label: "Message",
		name: "message",
		rows: "3",
	},
];
