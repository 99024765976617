import "./Contact.css";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { contactBannerItems } from "./contactBannerItems";
import { contactItems } from "./contactItems";
import Subscription from "../subscription/Subscription";
import Footer from "../footer/Footer";

class Contact extends React.Component {
	componentDidMount() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "instant",
		});
		this.props.setURL(window.location.pathname);
	}

	renderContactBannerItems() {
		return _.map(
			contactBannerItems,
			({ contactNum, link, icon, action }, i) => {
				return (
					<div key={contactNum} className="col-3 contact-banner-means">
						<div
							className={`flex-center contact-banner-contact${contactNum} green-back`}
						>
							<a
								className="align-center"
								href={link}
								target={contactNum === "3" ? "_blank" : ""}
								rel="noopener noreferrer"
							>
								<div className="row">
									<div className="col-12">
										<i className={`fas fa-${icon} fa-2x`}></i>
									</div>
									<div className="col-12">{action}</div>
								</div>
							</a>
						</div>
					</div>
				);
			}
		);
	}

	renderContactItems() {
		return _.map(contactItems, ({ title, component }, i) => {
			return (
				<div
					key={title}
					className="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 contact-col-div"
				>
					<div className="col-sm-8 offset-sm-2 col-lg-10 offset-lg-1">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<h4 className="title">{title}</h4>
									<hr className="half-hr-small" />
								</div>
								{component}
							</div>
						</div>
					</div>
				</div>
			);
		});
	}

	render() {
		return (
			<div className="row">
				<div className="col-12">
					<div className="row banner-pic-main-div">
						<div className="col-12 contact-banner-pic"></div>
						<div className="col-12 flex-left overlay">
							<div className="col-sm-7 col-md-6 offset-md-1 col-xl-4 offset-xl-1 contact-banner-pic-text">
								<div className="col-12 default-padding">
									<h2 className="banner-pic-title">Reach Out</h2>
									<p className="col-lg-9">
										We pride ourselves in paying close attention to our
										customers' needs. We do our best to address any questions or
										concerns that you may have at our earliest convenience.
									</p>
									<h4>Feel free to</h4>
									<div className="col-12">
										<div className="row">{this.renderContactBannerItems()}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-12 contact-content-div">
					<div className="row">{this.renderContactItems()}</div>
				</div>
				<Subscription />
				<Footer />
			</div>
		);
	}
}

export default connect(null, actions)(Contact);
