import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

const getMapOptions = (maps) => {
	return {
		disableDefaultUI: true,
		mapTypeControl: true,
		streetViewControl: false,
		fullscreenControl: true,
		clickableIcons: false,
		gestureHandling: "cooperative",
		styles: [
			{
				featureType: "poi",
				elementType: "labels",
				stylers: [{ visibility: "on" }],
			},
		],
	};
};

export default function OfficeLocation() {
	return (
		// Important! Always set the container height explicitly
		<div className="col-12 radius-map no-pad">
			<GoogleMapReact
				bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
				defaultCenter={{ lat: 33.77535, lng: -84.30107 }}
				defaultZoom={15}
				options={getMapOptions}
			>
				<Marker
					lat="33.77535"
					lng="-84.30107"
					name="Bella Waxing Salon"
					address="340 W. Ponce De Leon Ave, Suite 202"
					phone="678-718-5816"
					directions="https://www.google.com/maps/dir//340+W+Ponce+de+Leon+Ave,+Decatur,+GA+30030,+USA/@33.775185,-84.3014236,18z/data=!4m9!4m8!1m0!1m5!1m1!1s0x88f5073d4821aeb5:0xbb6aa9d3d4ca34a4!2m2!1d-84.301047!2d33.77536!3e3"
				/>
			</GoogleMapReact>
		</div>
	);
}
