import React from "react";
import Feedback from "../feedback/Feedback";
import OfficeLocation from "../google-map/OfficeLocation";

export const contactItems = [
	{
		title: "Your Thoughts",
		component: <Feedback />,
	},
	{
		title: "Our Location",
		component: <OfficeLocation />,
	},
];
