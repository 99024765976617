export const youCanExpectItems = [
	{
		iconNum: "1",
		icon: "business-time",
		subtitle: "Professionalism",
		text:
			"We have made a personal commitment to develop and improve our skills. We keep this knowledge up-to-date and continue to deliver the best work possible.",
	},
	{
		iconNum: "2",
		icon: "hand-sparkles",
		subtitle: "Cleanliness",
		text:
			"Cleanliness is next to Godliness. Our goal is to accommodate you in a fresh and clean environement. By committing to this culture, we avoid any possible contamination to you and others.",
	},
	{
		iconNum: "3",
		icon: "map-signs",
		subtitle: "Convenience",
		text:
			"We are located in the heart of Decatur within a 6-min walk from Decatur Square. Parking is never a problem, and our newly renovated salon is spacious, as well as comfortable.",
	},
	{
		iconNum: "4",
		icon: "mobile-alt",
		subtitle: "Online Booking",
		text:
			"Our customers can conveniently book appointments online. The platform is easy to use; the process is short and sweet; and the follow up communication makes it a breeze to keep track or update your booking(s) as needed.",
	},
	{
		iconNum: "5",
		icon: "calendar-times",
		subtitle: "Free Cancellation",
		text: `We understand that situations may arise in which an appointment cancellation is needed. We just require that our patrons request a cancellation 24 hours prior to their appointment time. This can be done online via your confirmation email link or by phone.`,
	},
	{
		iconNum: "6",
		icon: "money-bill-wave",
		subtitle: "Reasonable Prices",
		text:
			"You don't have to break your bank to get good service. We are firm believers that great experiences should not cost a fortune. Our prices are not only reasonable but they are also unbeatable.",
	},
];
