export const whatPeopleSayItems = [
	{
		date: "2021/8/13",
		stars: 5,
		text:
			"Went there yesterday for the first time, so glad I did. 10/10 would recommend. Adorable spot, super clean, the technician was so friendly. It was also very quick and efficient.",
		link:
			"https://www.yelp.com/biz/bella-waxing-salon-decatur?hrid=7zcOjGcIrxLb8eCE5dfCPg&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
		pictureClass: "katherine-a-pic",
		profileName: "Katherine A.",
		reviewNum: "01",
	},
	{
		date: "2021/8/1",
		stars: 5,
		text:
			"The owner is super fun to talk to and provided the most low-pain waxing experience I've ever had. Fast and efficient with competitive pricing. Will definitely be coming back!",
		link: "https://goo.gl/maps/v4PzgMXMWTdLhYo69",
		pictureClass: "rachel-cronin-pic",
		profileName: "Rachel Cronin",
		reviewNum: "02",
	},
	{
		date: "2021/7/1",
		stars: 5,
		text:
			"What a great experience. Very professional in great area. I was early for my appointment and I was able to get in early. I was comfortable and the owner did my wax. She is quick and it’s a new spot...",
		link: "https://goo.gl/maps/kWnw5MzEeJtA2vwHA",
		pictureClass: "teaba-b-pic",
		profileName: "Teaba B.",
		reviewNum: "03",
	},
	{
		date: "2021/6/1",
		stars: 5,
		text: "Very knowledgeable and super sweet and sincere!",
		link: "https://goo.gl/maps/24QSJp4XuJsUQPjt6",
		pictureClass: "liz-bock-pic",
		profileName: "Liz Bock",
		reviewNum: "04",
	},
	{
		date: "2020/2/1",
		stars: 5,
		text:
			"Just left my first wax with Meron here at Bella, and she was amazing!!! She is so friendly and professional, and she immediately made me feel right at ease. And she is very good at what she does.",
		link: "https://goo.gl/maps/tEvWtF43nWa6ZnjEA",
		pictureClass: "marla-johnson-pic",
		profileName: "Marla Johnson",
		reviewNum: "05",
	},
	{
		date: "2019/10/22",
		stars: 5,
		text:
			"Highly recommended. Clean, cute salon with professional atmosphere. Proprietor did a great job on my Brazilian wax	and I'll be back for more.",
		link:
			"https://www.yelp.com/biz/bella-waxing-salon-decatur?hrid=yoTY7VdjHRlMXE_0fRf9Mw&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)",
		pictureClass: "terri-s-pic",
		profileName: "Terri S.",
		reviewNum: "06",
	},
	{
		date: "2019/9/1",
		stars: 5,
		text:
			"10/10, would visit again. Very nice to talk to, thorough, but gentle, and reasonably priced. Will absolutely recommend to others.",
		link: "https://goo.gl/maps/213zUZFfAhdJmrGQ6",
		pictureClass: "brooke-d-pic",
		profileName: "Brooke Diamalanta",
		reviewNum: "07",
	},
	{
		date: "2019/8/1",
		stars: 5,
		text:
			"This was such a great find! She was gentle and quick with a great “bedside manner.” The place is impeccably clean and with lovely colors. I’m looking forward to returning soon!",
		link: "https://goo.gl/maps/TkyPUTMDGyPZ7Wtu5",
		pictureClass: "terri-n-pic",
		profileName: "Terri Nicole",
		reviewNum: "08",
	},
	{
		date: "2019/6/1",
		stars: 4,
		text:
			"I'm a big fan of Bella Waxing Salon. They are fast, efficient, and affordable! I also love that they offer a	package deal for brazilians. The waxer is friendly and she knows how to make her clients feel comfortable. Recommend!",
		link: "https://goo.gl/maps/fy5vxs7w3CTjVf789",
		pictureClass: "christie-l-pic",
		profileName: "Christie Luu",
		reviewNum: "09",
	},
];
