import "./TopRow.css";
import React from "react";
import { Link } from "react-router-dom";

class TopRow extends React.Component {
	render() {
		return (
			<div className="col-12" id="top-row">
				<div className="row">
					<div className="col-12 col-xl-4 flex-left" id="hours">
						<h6 className="align-center">
							Mon - Fri: 9:00am - 6:00pm / Sat: 9:00am - 4:00pm / Sun: Closed
						</h6>
					</div>
					<div className="col-12 col-xl-4" id="social">
						<ul className="align-center">
							<li>
								<a
									href="https://www.facebook.com/bellawaxingsalon/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i id="social-icons" className="fab fa-facebook-square"></i>
								</a>
							</li>
							<li>
								<a
									href="https://www.instagram.com/bellawaxingsalon/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i id="social-icons" className="fab fa-instagram"></i>
								</a>
							</li>
						</ul>
					</div>

					<div className="col-12 col-xl-4 flex-right" id="address">
						<h6 className="align-center">
							<Link to="salon-location">
								<i className="fas fa-map-marker-alt"></i> 340 W. Ponce De Leon
								Ave, Suite 202, Decatur GA, 30030
							</Link>
						</h6>
					</div>
				</div>
			</div>
		);
	}
}

export default TopRow;
