import "./BottomRow.css";
import React from "react";
import { Link } from "react-router-dom";
import SideNav from "./SideNav";
import LogoImage from "../img/logo_cropped.png";
import { animateScroll as scroll } from "react-scroll";

class BottomRow extends React.Component {
	componentDidMount() {
		// Large services sub-menu display trigger
		const servicesMenu = document.querySelector(".services-menu");
		const navItemServices = document.querySelector(".nav-item-services");

		navItemServices.addEventListener("mouseenter", function () {
			servicesMenu.classList.remove("d-none");
		});
		navItemServices.addEventListener("mouseleave", function () {
			servicesMenu.classList.add("d-none");
		});
	}

	scrollToTop = () => {
		scroll.scrollToTop({
			duration: 1000,
			delay: 0,
			smooth: true,
		});
	};

	render() {
		return (
			<div className="col-12" id="bottom-row">
				<div className="row">
					<div className="col-3 col-xl-12" id="company-initials">
						<Link to="/" title="Home" onClick={this.scrollToTop}>
							BWS
						</Link>
					</div>
					<div className="col-6 offset-0 col-xl-2 offset-xl-5" id="logo">
						<Link to="/" title="Home" onClick={this.scrollToTop}>
							<img id="logo-img" src={LogoImage} alt="company logo" />
						</Link>
					</div>
					<div id="navbar" className="col-3 col-xl-12">
						<ul id="large-navBar">
							<li>
								<Link to="/" title="Home">
									<span className="icon flaticon-house-outline"></span>
								</Link>
							</li>
							<li className="nav-item-services">
								{/*<a href="javascript:;">Services</a>*/}
								<div className="bottom-row-services-nav-button">Services</div>
								<div className="col-8 offset-4 services-menu d-none">
									<div className="align-left">
										<Link to="waxing-services">Waxing Services</Link>
									</div>
									<div className="align-left">
										<Link to="facial-services">Facial Services</Link>
									</div>
								</div>
							</li>
							<li>
								<Link to="guidelines">Guidelines</Link>
							</li>
							<li>
								<Link to="contact-us">Contact Us</Link>
							</li>
							<li>
								<Link to="online-booking" id="booking-btn">
									Book Online
								</Link>
							</li>
						</ul>

						<SideNav />
					</div>
				</div>
			</div>
		);
	}
}

export default BottomRow;
