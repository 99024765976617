import _ from "lodash";
import "./WeOffer.css";
import React from "react";
import { Link } from "react-router-dom";
import { weOfferItems } from "./weOfferItems";

class WhatWeOffer extends React.Component {
	renderWeOfferItems() {
		return _.map(
			weOfferItems,
			({ pictureClass, title, text, link, buttonText }, i) => {
				return (
					<div key={pictureClass} className="row">
						<div
							className={`col-12 col-sm-6 padding-container ${
								i === 1 || i === 3 ? "webkit-order" : ""
							}`}
						>
							<div className={`we-offer-ind-pic${pictureClass}`}></div>
						</div>
						<div className="col-12 col-sm-6 padding-container">
							<div className="flex-center white-back we-offer-ind-text">
								<div>
									<h4 className="col-12 align-center we-offer-ind-title">
										{title}
									</h4>
									<p className="grey align-center">{text}</p>
									<Link
										className="col-12 col-lg-8 offset-lg-2 btn btn-link"
										to={link}
									>
										{buttonText} <span className="arrow">&#8594;</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
				);
			}
		);
	}
	render() {
		return (
			<div className="we-offer-main-div">
				<div className="col-12">
					<h4 className="title">We Offer</h4>
					<hr className="half-hr" />
					<br />
				</div>
				<div className="container we-offer-container">
					{this.renderWeOfferItems()}
				</div>
			</div>
		);
	}
}

export default WhatWeOffer;
