import "./Facial.css";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Banner from "../common/Banner";
import Subscription from "../subscription/Subscription";
import Footer from "../footer/Footer";
import { facialTreatmentBenefitsModalContent } from "./facialTreatmentBenefitsModalContent";
import { facialServicesItems } from "./facialServicesItems";

class Facial extends React.Component {
	componentDidMount() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "instant",
		});
		this.props.setURL(window.location.pathname);
	}

	renderFacialServicesItems() {
		return _.map(
			facialServicesItems,
			(
				{
					cardTextClassSelector,
					nameOfFacialTreatment,
					cost,
					skinTypes,
					cardText,
					treatmentTime,
					seriesOfTreatment,
				},
				i
			) => {
				return (
					<div
						key={nameOfFacialTreatment}
						className="col-12 col-sm-6 col-xl-4 facial-card-outer-div"
					>
						<div className="card mb-3">
							<div className="card-header white green-back">
								<div className="row">
									<div className="col-8 col-lg-9 col-xl-8 title white align-left">
										{nameOfFacialTreatment}
									</div>
									<div className="col-4 col-lg-3 col-xl-4 align-right">
										{cost}
									</div>
								</div>
							</div>
							<div className="card-body grey">
								<h6 className="light-grey bold">Skin types</h6>
								<h4 className="card-title green skin-types">{skinTypes}</h4>
								<p
									className={`card-text card-text-class-selector-${cardTextClassSelector}`}
								>
									{cardText}
								</p>
								<h6>
									<span className="grey bold">Treatment time: </span>
									{treatmentTime}
								</h6>
								<h6>
									<span className="grey bold">Series of 6: </span>
									{seriesOfTreatment}
								</h6>
							</div>
						</div>
					</div>
				);
			}
		);
	}

	renderFacialTreatmentBenefitsModal = () => {
		(function ($) {
			$(function () {
				const {
					facialTreatmentModalTitle,
					facialTreatmentModalBody,
				} = facialTreatmentBenefitsModalContent;
				$("#myModalTitle").html(facialTreatmentModalTitle);
				$(".myModalBody").html(facialTreatmentModalBody);
			});
		})(window.jQuery);
	};

	render() {
		return (
			<div className="row facial-main-div">
				<Banner
					bannerPicClass="facial-services-banner-pic"
					title="Facial Services"
					text="You are beginning a journey toward younger and healthier skin."
					buttonDivClasses="col-12 col-md-6 col-lg-4 flex-center"
					buttonMessage="Book an appointment online"
					link="/online-booking"
					buttonClasses="btn-lg service-appointment"
				/>
				<div className="services-main-div">
					<div className="container services-container">
						<div className="row facial-treatment-cards-row">
							<div className="col-12 facial-treatment-main-title-div">
								<div className="flex-center service-svg-div">
									<i className="fas fa-spa fa-3x light-grey"></i>
								</div>
								<h4 className="service-title">Treatments</h4>
								<hr className="half-hr" />
							</div>
							{this.renderFacialServicesItems()}
						</div>
					</div>
				</div>
				<div className="col-12 service-filler-main-div no-pad">
					<div className="col-12 facial-service-filler-pic-div"></div>
					<div className="col-12 flex-center white service-filler-overlay">
						<div>
							<h5 className="col-10 offset-1 align-center">
								Beautiful skin for life is not a complicated recipe. You can
								look your best, feel your best and enjoy beautiful skin and
								optimum health every day of your life.
							</h5>
							<div className="col-12 flex-center">
								<button
									className="btn btn-lg service-filler-button facial-modal"
									data-bs-toggle="modal"
									data-bs-target="#global-modal"
									onClick={this.renderFacialTreatmentBenefitsModal}
								>
									Learn more
								</button>
							</div>
						</div>
					</div>
				</div>
				<Subscription />
				<Footer />
			</div>
		);
	}
}

export default connect(null, actions)(Facial);
