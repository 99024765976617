import "./Landing.css";
import "../fonts/css/all.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Carousel from "./Carousel";
import YouCanExpect from "./YouCanExpect";
import WeOffer from "./WeOffer";
import WhatPeopleSay from "./WhatPeopleSay";
import Footer from "../footer/Footer";
import { Element } from "react-scroll";

class Landing extends React.Component {
	componentDidMount() {
		this.props.setURL(window.location.pathname);
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="row landing-main-div">
				<Carousel />
				<Element name="slide1" className="col-12 spacer"></Element>
				<div className="col-12">
					<div className="row">
						<YouCanExpect />
						<WeOffer />
						<WhatPeopleSay />
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default connect(null, actions)(Landing);
