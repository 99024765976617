import "./BottomFooter.css";
import "../fonts/css/all.css";
import React from "react";
import { PictureCredits, DesignCredit } from "./bottomFooterContent";

class BottomFooter extends React.Component {
	render() {
		return (
			<div className="row" id="bottom-footer">
				<div className="col-12 col-xl-4" id="copyright">
					<span id="copyright-text">Copyright </span>&copy; Bella Waxing Salon
				</div>
				<PictureCredits />
				<DesignCredit />
			</div>
		);
	}
}

export default BottomFooter;
