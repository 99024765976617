import "./FacialWaxing.css";
import React from "react";
import { upperBodyWaxingItems } from "./waxingItems";
import { WaxingBlock } from "./commonWaxingComponents";

const { title, vector } = upperBodyWaxingItems.logo;
const { forHer, forHim } = upperBodyWaxingItems;

class UpperBodyWaxing extends React.Component {
	render() {
		return (
			<WaxingBlock
				title={title}
				vector={vector}
				arrForHer={forHer}
				arrForHim={forHim}
			/>
		);
	}
}

export default UpperBodyWaxing;
