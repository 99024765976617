export const facialServicesItems = [
	{
		cardTextClassSelector: "zero",
		nameOfFacialTreatment: "Ormedic Facial",
		cost: "$95.00",
		skinTypes: "Sensitive, Unbalanced, Pregnant, Teens",
		cardText:
			"Restore the balance to your skin with this innovative facial combining the perfect marriage of organic ingredients with medical effectiveness. Even the most sensitive skin types will benefit tremendously from this soothing, yet highly effective organic cocktail of essential oils, botanical extracts and medical grade peptides. It will deliver the maximum restoration to tired, dull and lack of luster skin. The Ormedic Facial uses no chemicals, acids or parabens.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$500",
	},
	{
		cardTextClassSelector: "one",
		nameOfFacialTreatment: "Anti-Oxidant Facial",
		cost: "$125.00",
		skinTypes: "Rosacea, Dehydrated, Photo Aging",
		cardText:
			"This comprehensive treatment combines stable Tri Vitamin C blend with, correcting hydroxyl acids and deeply hydrating ingredients to treat rosacea, dry, dehydrated and photo aging skin.  After just one treatment you will know why Vitamin C is a must have anti-aging ingredient.  This amazing treatment lightens, brightens and tightens reducing redness and adding vital hydration immediately leaving you with younger looking skin.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$690.00",
	},
	{
		cardTextClassSelector: "two",
		nameOfFacialTreatment: "The Max Facial",
		cost: "$135.00",
		skinTypes: "Aging, Dehydrated, ALL Skin Types",
		cardText:
			"The Ultimate Anti-Aging and Hydrating Facial.  Receive the MAXIMUM in anti-aging prevention while simultaneously drenching your skin in luxurious hydration.  This progressive exfoliating treatment containing the most advanced fusion of ingredients including plant derived stem cells, peptides, AHA’s, and new sophisticated forms of Vitamin C all work together to provide the MAXIMUM in Correction, Prevention, and Nutrition to even the most neglected skin.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$750.00",
	},
	{
		cardTextClassSelector: "three",
		nameOfFacialTreatment: "Purifying Facial",
		cost: "$125.00",
		skinTypes: "Oily, Acne and Acne Prone Skin",
		cardText:
			"Say goodbye to annoying breakouts. This treatment combines powerful anti-bacterial ingredients with soothing botanicals to fight acne lesions without irritating delicate skin.  Perfect for treating oily, acne, and acne prone skin.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$690.00",
	},
	{
		cardTextClassSelector: "four",
		nameOfFacialTreatment: "The Max Men's Facial",
		cost: "$125.00",
		skinTypes: "Aging, Dehydrated, All Skin Types",
		cardText:
			"Calling all men. This comprehensive treatment combines the latest ingredients of plant derived stem cells to slow the aging process while powerful peptides stimulate collagen for instantly firmer looking skin.  Soothing botanicals nourish the skin, calming post shave irritation.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$690.00",
	},
	{
		cardTextClassSelector: "five",
		nameOfFacialTreatment: "Illuminating Facial",
		cost: "$125.00",
		skinTypes: "Hyperpigmentation",
		cardText:
			"This amazing treatment diminishes dark spots using the latest innovations in lightening agents, and powerful exfoliants to create a glowing even tone on all skins from ebony to ivory.",
		treatmentTime: "50 mins",
		seriesOfTreatment: "$690.00",
	},
];
