import _ from "lodash";
import React from "react";
import { reduxForm, Field, reset, startSubmit, stopSubmit } from "redux-form";
import { subscriptionFields } from "./subscriptionFields";
import { subscriptionFormFields } from "./subscriptionFormFields";
import { validateEmails } from "../../utils/validateEmails";
import { ReactComponent as Spinner } from "../svg/spinner.svg";

class NewSubscriptionSubmit extends React.Component {
	state = { alignCenter: false };

	componentDidMount() {
		let subscriptionSubtitle = document.querySelector(".subscriber-subtitle");
		let subForm = document.querySelector(".sub-form");

		subscriptionSubtitle.classList.remove("d-none");
		subForm.classList.remove("d-none");
	}

	renderFields() {
		return _.map(subscriptionFields, ({ icon, label, name }) => {
			return (
				<Field
					key={name}
					component={subscriptionFormFields}
					type="text"
					icon={icon}
					label={label}
					name={name}
				/>
			);
		});
	}

	renderSubmissionStatus() {
		const subscription = document.querySelector(".subscribe-main-div");

		if (!this.props.subscriptionStatus) {
			return;
		}

		if (this.props.subscriptionStatus.errorMessage) {
			const { errorMessage } = this.props.subscriptionStatus;

			this.props.dispatch(stopSubmit("subscriptionForm"));
			subscription.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "start",
			});
			return <div className="alert alert-danger">{errorMessage}</div>;
		}

		if (this.props.subscriptionStatus.successMessage) {
			const { successMessage } = this.props.subscriptionStatus;

			this.props.dispatch(stopSubmit("subscriptionForm"));
			subscription.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "start",
			});
			return (
				<div className="sub-success alert alert-success">{successMessage}</div>
			);
		}
	}

	onSubmit = (formValues) => {
		this.props.onSubmit(formValues);
		this.props.dispatch(reset("subscriptionForm"));
		this.props.dispatch(startSubmit("subscriptionForm"));
	};

	render() {
		let subSuccess = document.querySelector(".sub-success");
		const { pristine, submitting } = this.props;

		return (
			<div className="subscribe-main-div">
				<div className="col-12">
					<h4 className="title">Subscribe</h4>
					<hr className="half-hr" />
					<br />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
							<p className="subscriber-subtitle">
								Sign up with your email address and receive updates.
							</p>

							{this.renderSubmissionStatus()}

							<form
								className={`sub-form ${subSuccess ? "d-none" : null}`}
								onSubmit={this.props.handleSubmit(this.onSubmit)}
							>
								{this.renderFields()}
								<div className="d-grid gap-2">
									<button
										type="submit"
										className="btn btn-lg btn-block"
										id="send"
										disabled={pristine || submitting}
									>
										{submitting === true ? (
											<Spinner />
										) : (
											<i className="fas fa-paper-plane"></i>
										)}
									</button>
								</div>
							</form>
							<p className="align-center respect">We respect your privacy</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function validate(values) {
	const errors = {};

	errors.email = validateEmails(values.email || "");

	_.each(subscriptionFields, ({ name }) => {
		if (!values[name]) {
			errors[name] = `${name[0].toUpperCase() + name.slice(1)} is required.`;
		}
	});

	return errors;
}

export default reduxForm({
	validate,
	form: "subscriptionForm",
})(NewSubscriptionSubmit);
