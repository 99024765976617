import React from "react";
import Button from "./Button";

const FillerImage = ({
	fillerImagePicDivClass,
	text,
	buttonDivClasses,
	buttonMessage,
	link,
	buttonClasses,
}) => {
	return (
		<div className="col-12 service-filler-main-div no-pad">
			<div className={`col-12 ${fillerImagePicDivClass}`}></div>
			<div className="col-12 flex-center white service-filler-overlay">
				<div>
					<h5 className="col-10 offset-1 align-center">{text}</h5>
					<Button
						divClass={buttonDivClasses}
						buttonText={buttonMessage}
						link={link}
						buttonClass={buttonClasses}
					/>
				</div>
			</div>
		</div>
	);
};

export default FillerImage;
