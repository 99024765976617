export const facialTreatmentBenefitsModalContent = {
	facialTreatmentModalTitle: `
		<div class="col-12 flex-center">
			<div class="guidelines-icon-div flex-center green-back">
				<i class="fas fa-spa fa-2x white"></i>
			</div>
		</div>
		<h4 class="green-modal-title align-center">Beautiful Skin</h4>
	`,
	facialTreatmentModalBody: `
		<p>Beautiful skin on the outside begins with good health on the inside.  That’s why we choose Image Skin Care and a cosmeceutical line of professional treatments and home care products.</p>
		<p>Image products work at a cellular level for optimal skin health to nourish, correct and protect.</p>
		<p>The Image philosophy is “Age Later”.  Skin is the window to the body’s overall health.</p>
		<p><strong>SKIN TYPES ARE GENETICALLY DETERMINED</strong>.  People are born with their skin type.  Genetics and ethnicity determine the type of skin they have.</p>
		<p><strong>SKIN CONDITIONS ARE DETERMINED BY AGE, PRODUCTS USED, AND LIFESTYLE</strong>.   Dry, normal, combination, oily, and sensitive are ever changing in a client’s lifetime.</p>
		<h5 class="align-left title healthy-skin-steps-title">7 STEPS TO HEALTHIER SKIN</h5>
		<ul class="guidelines-list">
			<li>Drink a healthy dose of H2O daily</li>
			<li>Eat clean</li>
			<li>Get adequate sleep every night</li>
			<li>Exercise 3 times per week to reduce stress</li>
			<li>Take essential antioxidants and supplements</li>
			<li>Use Image cosmeceuticals daily for facial rejuvenation</li>
			<li>Wear a broad spectrum environmental protection daily</li>
		</ul>
		<p>Are you on a balanced skincare diet?  The skin needs a personal trainer to get it to a healthy level.</p>
		<p>In order for your skin to age later, we must add ingredients to the skin daily for healthy, balanced skin.  Healthy skin is beautiful skin! The skin is the largest organ in the body.  As we age, all physiological mechanisms slow down.  The skin needs certain ingredients to prevent malnutrition of the skin, which results in sagging, wrinkles, and excessive brown spots on the skin.</p>
		<p>Over the counter products only address dead tissue.  Image professional cosmeceutical skincare treats LIVE TISSUE without the use of parabens and harmful chemicals.</p>
	`,
};
