import React from "react";
// import { Link } from "react-router-dom";

const AnnouncementModal = () => {
	return (
		<div
			className="modal fade announcement-modal"
			id="announcement-modal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="exampleModalCenterTitle"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header green-back white">
						<h5
							className="modal-title full-width align-center"
							id="exampleModalCenterTitle"
						>
							<span className="company-name medium">Bella Waxing Salon</span>
							<br />
							Schedule Announcement
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						>
							<i className="fas fa-window-close white"></i>
						</button>
					</div>
					<div className="modal-body announcement-modal-body">
						<p className="announcement-text">Dear clients,</p>
						<p className="announcement-text">
							Thank You For Trusting Me As Your Beauty Professional!
						</p>
						<p className="announcement-text">
							Due to my maternity leave, I have been unable to fulfill your
							beauty needs. I shall however be returning in December.
						</p>
						<p className="announcement-text">
							Thank you for your patience and loyalty!
						</p>
						<p className="announcement-text">Best regards,</p>
						<p className="announcement-text">
							<strong>Meron Haile</strong>
							<br />
							Owner, Bella Waxing Salon
						</p>
					</div>
					<div className="modal-footer">
						<div className="d-grid gap-2">
							<button
								type="button"
								className="btn btn-lg btn-block"
								data-bs-dismiss="modal"
								id="send"
							>
								GOT IT
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnnouncementModal;
