import "./SideNav.css";
import React from "react";
import { Link } from "react-router-dom";

class SmallMenu extends React.Component {
	componentDidMount() {
		// Sidenav services menu triangle rotator
		const rotater = document.querySelector(".rotater");
		rotater.addEventListener("click", function () {
			if ([...rotater.classList].includes("rotated")) {
				rotater.classList.remove("rotated");
				this.innerHTML = "Services &#9656;";
				return;
			}
			rotater.classList.add("rotated");
			this.innerHTML = "Services &#9662;";
		});

		(function ($) {
			$(function () {
				$(".closebtn, .sidenav-menu, .sidenav-sub-menu").on(
					"click",
					function () {
						$(".rotater").removeClass("rotated");
						$(".rotater").html("Services &#9656;");
						$("#collapseExample1").collapse("hide");
					}
				);
			});
		})(window.jQuery);
	}

	getElements = () => {
		// Open and close curtain menu and fade in the menu items consecutively
		const mySideNav = document.getElementById("mySidenav");
		const closeBtn = document.querySelector(".closebtn");
		const fadeUp = document.querySelectorAll(".fade-up");
		const servicesMenuCollapse = document.querySelector("#collapseExample1");
		const premieBlocker = document.querySelector(".premie-blocker"); // Prevent people from closing the menu before the fade in finishes
		const body = document.getElementsByTagName("BODY")[0];
		return {
			mySideNav,
			closeBtn,
			fadeUp,
			servicesMenuCollapse,
			premieBlocker,
			body,
		};
	};

	openNav = () => {
		const {
			mySideNav,
			closeBtn,
			fadeUp,
			servicesMenuCollapse,
			premieBlocker,
			body,
		} = this.getElements();
		mySideNav.style.width = "100vw";
		closeBtn.classList.remove("fadeOut");
		servicesMenuCollapse.classList.remove("fadeOut");
		closeBtn.classList.add("fadeIn");
		fadeUp.forEach((tag, i) => {
			setTimeout(function () {
				tag.classList.remove("fadeOut");
				tag.classList.add("fadeInUp");
			}, 200 * (i + 1));
		});
		setTimeout(function () {
			premieBlocker.classList.add("d-none");
		}, 1500);
		body.setAttribute("style", "overflow: hidden"); // Prevent people from scrolling the content page while the sidenav menu is open
	};

	closeNav = () => {
		const {
			mySideNav,
			closeBtn,
			fadeUp,
			servicesMenuCollapse,
			premieBlocker,
			body,
		} = this.getElements();
		premieBlocker.classList.remove("d-none");
		body.setAttribute("style", "overflow: scroll");
		fadeUp.forEach((tag) => {
			tag.classList.remove("fadeInUp");
			tag.classList.add("fadeOut");
		});
		closeBtn.classList.remove("fadeIn");
		closeBtn.classList.add("fadeOut");
		servicesMenuCollapse.classList.add("fadeOut");
		setTimeout(function () {
			mySideNav.style.width = "0";
		}, 1000);
	};

	render() {
		return (
			<div>
				<div id="mySidenav" className="sidenav">
					<span className="closebtn animated fadeOut" onClick={this.closeNav}>
						&times;
					</span>
					<div className="premie-blocker"></div>
					<div className="sidenav-content">
						<Link
							className="animated fadeOut fade-up sidenav-menu"
							to="/"
							onClick={this.closeNav}
						>
							<i className="icon flaticon-house-outline"></i>
						</Link>
						<a
							className="animated fadeOut fade-up sidenav-menu-services rotater"
							data-bs-toggle="collapse"
							href="#collapseExample1"
							aria-expanded="false"
							aria-controls="collapseExample1"
						>
							Services &#9656;
						</a>
						<div className="collapse animated white" id="collapseExample1">
							<div className="green-back card card-body">
								<Link
									className="sidenav-sub-menu"
									to="waxing-services"
									onClick={this.closeNav}
								>
									Waxing Services
								</Link>

								<Link
									className="sidenav-sub-menu"
									to="facial-services"
									onClick={this.closeNav}
								>
									Facial Services
								</Link>
							</div>
						</div>
						<Link
							className="animated fadeOut fade-up sidenav-menu"
							to="guidelines"
							onClick={this.closeNav}
						>
							Guidelines
						</Link>
						<Link
							className="animated fadeOut fade-up sidenav-menu"
							to="contact-us"
							onClick={this.closeNav}
						>
							Contact Us
						</Link>
						<Link
							className="animated fadeOut fade-up sidenav-menu"
							to="online-booking"
							onClick={this.closeNav}
						>
							Book Online
						</Link>
					</div>
				</div>
				<span
					className="icon flaticon-menu-2"
					id="navSpan"
					style={{
						fontSize: "30px",
						cursor: "pointer",
						float: "right",
						margin: "25px 0",
					}}
					onClick={this.openNav}
				></span>
			</div>
		);
	}
}

export default SmallMenu;
