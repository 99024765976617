import { combineReducers } from "redux";
import { reducer as reduxForm } from "redux-form";
import { urlReducer } from "./urlReducer";
import { subscriptionEmailReducer } from "./subscriptionEmailReducer";
import { feedbackEmailReducer } from "./feedbackEmailReducer";

export default combineReducers({
	url: urlReducer,
	subscription: subscriptionEmailReducer,
	feedback: feedbackEmailReducer,
	form: reduxForm,
});
