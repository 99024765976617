export const weOfferItems = [
	{
		pictureClass: "1",
		title: "Waxing",
		text:
			"Large areas of unwanted hair can be removed quickly with immediate results. The cost is relatively low and client satisfaction is high. Waxing has been around for decades. It is safe, convenient, and effective. You will love the smoothness that comes with waxing!",
		link: "/waxing-services",
		buttonText: "Waxing Services",
	},
	{
		pictureClass: "2",
		title: "Facial",
		text:
			"Beautiful skin for life is not a complicated recipe. You can look your best, feel your best and enjoy beautiful skin and	optimum health every day of your life.",
		link: "/facial-services",
		buttonText: "Facial Services",
	},
	{
		pictureClass: "3",
		title: "Massage",
		text:
			"Studies of the benefits of massage demonstrate that it is an	effective treatment for reducing stress, pain and muscle tension. Treat yourself to a soothing session with our	certified massage therapist.",
		link: "/waxing-services",
		buttonText: "Explore",
	},
	{
		pictureClass: "4",
		title: "Guidelines",
		text:
			"The well being of our clients is of the utmost importance. We urge everyone to take the time and read the pertinent	information contained within our guidelines page. Help us	ensure a safe and pleasant experience!",
		link: "/guidelines",
		buttonText: "Learn more",
	},
];
