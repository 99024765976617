import "./SalonLocation.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import OfficeLocation from "./OfficeLocation";

class SalonLocation extends React.Component {
	componentDidMount() {
		this.props.setURL("/salon-location");
	}

	render() {
		return (
			<div className="row">
				<div className="col-12">
					<div className="container">
						<div className="row">
							<div className="col-12 salon-location">
								<h4 className="title">Location</h4>
								<hr className="half-hr" />
							</div>

							<div className="col-12">
								<OfficeLocation />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(null, actions)(SalonLocation);
