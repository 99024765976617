import React from "react";
import _ from "lodash";
// import { Link } from "react-router-dom";

// Picture Credits
const pictureCreditsContent = [
	{
		link:
			"https://unsplash.com/photos/0BEqLfQwVzM?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
		name: "Towfiqu Barbhuiya",
	},
	{
		link:
			"https://unsplash.com/photos/g-m8EDc4X6Q?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink",
		name: "Engin Akyurt",
	},
];

const parsePictureCreditsContent = () => {
	return _.map(pictureCreditsContent, ({ link, name }, i) => {
		return (
			<a
				key={i}
				className="picture-credit-anchor"
				href={link}
				target="_blank"
				rel="noopener noreferrer"
				title="Download free do whatever you want high-resolution photos from Matthew Kane"
			>
				<span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 32 32"
						className="picture-credit-svg"
					>
						<path d="M20.8 18.1c0 2.7-2.2 4.8-4.8 4.8s-4.8-2.1-4.8-4.8c0-2.7 2.2-4.8 4.8-4.8 2.7.1 4.8 2.2 4.8 4.8zm11.2-7.4v14.9c0 2.3-1.9 4.3-4.3 4.3h-23.4c-2.4 0-4.3-1.9-4.3-4.3v-15c0-2.3 1.9-4.3 4.3-4.3h3.7l.8-2.3c.4-1.1 1.7-2 2.9-2h8.6c1.2 0 2.5.9 2.9 2l.8 2.4h3.7c2.4 0 4.3 1.9 4.3 4.3zm-8.6 7.5c0-4.1-3.3-7.5-7.5-7.5-4.1 0-7.5 3.4-7.5 7.5s3.3 7.5 7.5 7.5c4.2-.1 7.5-3.4 7.5-7.5z"></path>
					</svg>
				</span>
				<span>{name}</span>
			</a>
		);
	});
};
export const PictureCredits = () => {
	return (
		<div className="col-12 col-xl-4" id="picture-credit">
			<span id="picture-credit-text"> Pictures by </span>
			{parsePictureCreditsContent()}
		</div>
	);
};

// Design Credit
export const DesignCredit = () => {
	return (
		<div className="col-12 col-xl-4" id="design-credit">
			<div className="row">
				<div className="col-2" id="initials">
					<a
						href="https://menelik7.github.io/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Mt
					</a>
				</div>
				<div className="col-8" id="myName">
					Menelik Tefera 2018
				</div>
				<div className="col-2" id="github">
					<a
						href="https://github.com/menelik7"
						target="_blank"
						rel="noopener noreferrer"
					>
						<i className="fab fa-github"></i>
					</a>
				</div>
			</div>
		</div>
	);
};
