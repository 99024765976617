import _ from "lodash";
import "./YouCanExpect.css";
import React from "react";
import { youCanExpectItems } from "./youCanExpectItems";

class YouCanExpect extends React.Component {
	renderYouCanExpectItems() {
		return _.map(youCanExpectItems, ({ iconNum, icon, subtitle, text }, i) => {
			return (
				<div
					key={iconNum}
					className="col-12 col-md-6 col-lg-4 you-can-expect-ind-divs"
				>
					<div className="row">
						<div className="flex-center col-12">
							<div className="flex-center circ-icon-div">
								<span className="icon-num one">{iconNum}</span>
								<i className={`fas fa-${icon} fa-2x you-can-expect-icons`}></i>
							</div>
						</div>
						<h3 className="align-center col-12 you-can-expect-subtitle">
							{subtitle}
						</h3>
						<p className="align-center col-12 you-can-expect-text">{text}</p>
					</div>
				</div>
			);
		});
	}
	render() {
		return (
			<div className="you-can-expect-main-div">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h4 className="title">You Can Expect</h4>
							<hr className="half-hr" />
						</div>
						{this.renderYouCanExpectItems()}
					</div>
				</div>
			</div>
		);
	}
}

export default YouCanExpect;
