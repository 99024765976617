import "./App.css";
import "./animate.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "../history";
import Header from "./header/Header";
import Landing from "./landing/Landing";
import Waxing from "./waxing/Waxing";
import Facial from "./facial/Facial";
import Guidelines from "./guidelines/Guidelines";
import Contact from "./contact/Contact";
import Booking from "./booking/Booking";
import SalonLocation from "./google-map/SalonLocation";
import ScrollUpButton from "./scrollup/ScrollUpButton";
import AnnouncementModal from "./common/AnnouncementModal";
import { GlobalModal } from "./common/GlobalModal";

class App extends React.Component {
	// componentDidMount() {
	// 	(function ($) {
	// 		$(function () {
	// 			$(".announcement-modal").modal("show");
	// 		});
	// 	})(window.jQuery);
	// 	this.checkCookie();
	// }

	// // Set cookie for toggling display of announcement modal depending on last visited
	// setCookie = (cname, cvalue, exdays) => {
	// 	let d = new Date();
	// 	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	// 	let expires = "expires=" + d.toGMTString();
	// 	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	// };

	// getCookie = (cname) => {
	// 	let name = cname + "=";
	// 	let decodedCookie = decodeURIComponent(document.cookie);
	// 	let ca = decodedCookie.split(";");
	// 	for (let i = 0; i < ca.length; i++) {
	// 		let c = ca[i];
	// 		while (c.charAt(0) === " ") {
	// 			c = c.substring(1);
	// 		}
	// 		if (c.indexOf(name) === 0) {
	// 			return c.substring(name.length, c.length);
	// 		}
	// 	}
	// 	return "";
	// };

	// checkCookie = () => {
	// 	let status = this.getCookie("visited");
	// 	if (status === "") {
	// 		(function ($) {
	// 			$(function () {
	// 				// Manually Starting carousel after Link redirect since there is no page load.
	// 				$(".announcement-modal").modal("show");
	// 			});
	// 		})(window.jQuery);
	// 		status = "true";
	// 		this.setCookie("visited", status, 7);
	// 	}
	// };

	render() {
		return (
			<BrowserRouter history={history}>
				<Header />
				<div className="container-fluid main-router-div">
					<Routes>
						<Route path="/" exact element={<Landing />} />
						<Route path="/waxing-services" exact element={<Waxing />} />
						<Route path="/facial-services" exact element={<Facial />} />
						<Route path="/guidelines" exact element={<Guidelines />} />
						<Route path="/contact-us" exact element={<Contact />} />
						<Route path="/online-booking" exact element={<Booking />} />
						<Route path="/salon-location" exact element={<SalonLocation />} />
					</Routes>
					<ScrollUpButton />
				</div>
				<AnnouncementModal />
				<GlobalModal />
			</BrowserRouter>
		);
	}
}

export default App;
