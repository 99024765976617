import "./Waxing.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Banner from "../common/Banner";
import FacialWaxing from "./FacialWaxing";
import UpperBodyWaxing from "./UpperBodyWaxing";
import LowerBodyWaxing from "./LowerBodyWaxing";
import SpecialPackages from "./SpecialPackages";
import FillerImage from "../common/FillerImage";
import Subscription from "../subscription/Subscription";
import Footer from "../footer/Footer";
import { GlobalModal } from "../common/GlobalModal";

class Waxing extends React.Component {
	componentDidMount() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "instant",
		});
		this.props.setURL(window.location.pathname);
	}

	render() {
		return (
			<div className="row">
				<Banner
					bannerPicClass="waxing-services-banner-pic"
					title="Waxing Services"
					text="Our prices are reasonable and our delivery is second to none. Indulge in the best waxing service that Decatur has to offer."
					buttonDivClasses="col-12 col-md-6 col-lg-4 flex-center"
					buttonMessage="Book an appointment online"
					link="/online-booking"
					buttonClasses="btn-lg service-appointment"
				/>
				<div className="col-12 services-main-div">
					<div className="container services-container">
						<FacialWaxing />
						<UpperBodyWaxing />
						<LowerBodyWaxing />
						<SpecialPackages />
					</div>
				</div>
				<FillerImage
					fillerImagePicDivClass="waxing-service-filler-pic-div"
					text="As we aspire to get you relaxed and comfortable, we urge you to"
					buttonDivClasses="col-12 flex-center"
					buttonMessage="please read our guidelines"
					link="/guidelines"
					buttonClasses="btn btn-lg service-filler-button"
				/>
				<Subscription />
				<GlobalModal />
				<Footer />
			</div>
		);
	}
}

export default connect(null, actions)(Waxing);
