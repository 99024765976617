import React from "react";

export const subscriptionFormFields = ({
	input,
	icon,
	label,
	type,
	meta: { error, touched },
}) => {
	return (
		<div className="form-group form-icon-div">
			<i className={`fas fa-${icon} green`}></i>
			<input
				className="form-control"
				id="email"
				{...input}
				placeholder={label}
				type={type}
				autoComplete="off"
			/>
			<small className="text-danger">{touched && error}</small>
		</div>
	);
};
