import _ from "lodash";
import "./WhatPeopleSay.css";
import React from "react";
import { whatPeopleSayItems } from "./whatPeopleSayItems";

class WhatPeopleSay extends React.Component {
	componentDidMount() {
		(function ($) {
			$(function () {
				// Manually Starting carousel after Link redirect since there is no page load.
				$("#whatPaopleSayCarousel").carousel();

				// What People Say Months Ago
				const d = new Date();
				let condMonth = $(".cond-month");
				$(".months-ago").each(function (i) {
					let d1 = new Date($(this).text());
					let diff = Math.floor((d - d1) * 0.00000000038);
					if (diff < 12) {
						$(this).html(diff);
					} else {
						let year = Math.floor(diff / 12);
						$(condMonth[i]).css("display", "none");
						$(this).html(year + " year");
					}
				});
			});
		})(window.jQuery);
	}

	renderWhatPaopleSayCarouselItemIndicator() {
		return _.map(whatPeopleSayItems, ({ profileName }, i) => {
			return (
				<button
					key={i}
					type="button"
					data-bs-target="#whatPaopleSayCarousel"
					data-bs-slide-to={`${i}`}
					className={i === 0 ? "active" : null}
					aria-current="true"
					aria-label={`Slide ${i + 1}`}
				></button>
			);
		});
	}

	renderStars = (numOfStars, arr = [], counter = 1) => {
		while (counter <= 5) {
			arr.push(
				<i
					key={counter}
					className={`${counter > numOfStars ? "far" : "fas"} fa-star`}
				></i>
			);
			counter++;
		}
		return arr;
	};

	renderWhatPaopleSayCarouselItems() {
		return _.map(
			whatPeopleSayItems,
			(
				{ date, stars, text, link, pictureClass, profileName, reviewNum },
				i
			) => {
				return (
					<div key={i} className={`carousel-item ${i === 0 ? "active" : null}`}>
						<div className="what-people-say-ind-divs">
							<div className="what-people-say-ind-text-div">
								<div className="row">
									<div className="col-12 green stars">
										{this.renderStars(stars)}
									</div>
									<div className="col-12 review-date">
										<span className="months-ago">{date}</span>
										<span className="cond-month"> months</span> ago
									</div>
								</div>
								<div className="what-people-say-ind-text">{text}</div>
							</div>
							<div className="what-people-say-ind-profile">
								<a href={link} target="_blank" rel="noopener noreferrer">
									<div className={pictureClass}></div>
								</a>
								<div className="row">
									<div className="col-7">
										<a
											className="profile-name"
											href={link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{profileName}
										</a>
									</div>
									<div className="col-5 align-right review-num">
										{reviewNum} <span className="denominator">/09</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		);
	}

	render() {
		return (
			<div className="what-people-say-main-div">
				<div className="col-12">
					<h4 className="title">What People Say</h4>
					<hr className="half-hr" />
					<br />
				</div>
				<div className="container what-people-say-container">
					<div
						id="whatPaopleSayCarousel"
						className="carousel slide"
						data-bs-ride="carousel"
					>
						<div className="carousel-indicators">
							{this.renderWhatPaopleSayCarouselItemIndicator()}
						</div>
						<div className="carousel-inner">
							{this.renderWhatPaopleSayCarouselItems()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhatPeopleSay;
