import React from "react";
import Button from "../common/Button";

export const carouselItems = [
	{
		companyMoto: "Clean, Professional, Curteous.",
		companyName: "Bella Waxing Salon",
		carouselPictureClass: "carousel-one",
		serviceMessage:
			"We are conveniently located in one of the trendiest parts of the Atlanta Metropolitan area. Armed with a knowledgeable staff, we look forward to serving you in our new salon.",
		button: (
			<Button
				divClass="flex-center"
				buttonText="Book An Appointment"
				link="/online-booking"
				buttonClass="btn btn-lg carousel-btn1"
			/>
		),
	},
	{
		companyMoto: "Tired of Disappointments?",
		companyName: "We Got You Covered",
		carouselPictureClass: "carousel-two",
		serviceMessage:
			"We aspire to meet the needs of every single customer that walks through our doors. Please explore the different options and prepare yourself for a revitalizing experience.",
		button: (
			<Button
				divClass="flex-center"
				buttonText="Check Out Our Services"
				link="/waxing-services"
				buttonClass="btn btn-lg carousel-btn2"
			/>
		),
	},
	{
		companyMoto: "Worried about safety?",
		companyName: "We Take No Chances",
		carouselPictureClass: "carousel-three",
		serviceMessage:
			"In addition to informing our customers of the many personal care guidelines, we go to great lengths in keeping our establishment clean and our staff well informed.",
		button: (
			<Button
				divClass="flex-center"
				buttonText="Learn more"
				link="/guidelines"
				buttonClass="btn btn-lg carousel-btn3"
			/>
		),
	},
];
