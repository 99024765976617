import "./Header.css";
import "../fonts/flaticon.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import TopRow from "./TopRow";
import BottomRow from "./BottomRow";
import Button from "../common/Button";
import { bookingPolicyModalContent } from "../guidelines/bookingPolicyModalContent";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = { currentUrl: window.location.pathname };
	}

	componentDidMount() {
		// console.log("Mounted");
		this.setHeaderToggle();
	}

	componentDidUpdate() {
		// Run this only when navigating away from current page
		if (this.state.currentUrl !== this.props.url) {
			this.setHeaderToggle();
		}
	}

	setHeaderToggle = () => {
		this.setState({ currentUrl: this.props.url });
		if (
			this.props.url === "/online-booking" ||
			this.props.url === "/salon-location"
		) {
			// In and out elements should remain visible for this page so we don't do anything - the redux store reference in the header tag toggles that
			const inAndOut = document.querySelectorAll(".in-and-out");
			inAndOut.forEach((element) => element.classList.remove("d-none"));
			window.removeEventListener("scroll", this.headerDisplayToggle);
			return;
		}
		// Header should be hidden at top for any page other than "online-booking" and we call the toggle sequence
		window.addEventListener("scroll", this.headerDisplayToggle);
	};

	headerDisplayToggle = () => {
		// console.log("Condition hidden");
		const inAndOut = document.querySelectorAll(".in-and-out");
		if (window.scrollY > window.innerHeight / 2) {
			// console.log("Scroll Y over limit!");
			inAndOut.forEach((element) => {
				element.classList.add("fadeIn");
				element.classList.remove("d-none");
			});
		} else {
			// console.log("Scroll Y under limit!");
			inAndOut.forEach((element) => {
				element.classList.remove("fadeIn");
				element.classList.add("d-none");
			});
		}
	};

	toggleFixedHeaderBookOnlineButton() {
		const URL = window.location.pathname;
		if (URL === "/waxing-services" || URL === "/facial-services") {
			return "";
		}
		return "d-none";
	}

	// Insert Booking Policy modal inner html
	renderBookingPolicyModal = () => {
		(function ($) {
			$(function () {
				const { modalTitle, modalBody } = bookingPolicyModalContent;
				$("#myModalTitle").html(modalTitle);
				$(".myModalBody").html(modalBody);
			});
		})(window.jQuery);
	};

	toggledisplayOfBookingPolicyButton() {
		const URL = window.location.pathname;
		if (URL === "/online-booking" || URL === "/salon-location") {
			return "";
		}
		return "d-none";
	}

	render() {
		return (
			<div className="container-fluid header">
				<div
					className={`row animated in-and-out ${
						this.props.url === "/online-booking" ||
						this.props.url === "/salon-location"
							? ""
							: " d-none"
					}`}
					id="header"
				>
					<TopRow />
					<BottomRow />
					<Button
						divClass={`col-6 offset-3 col-lg-4 offset-lg-4 ${this.toggleFixedHeaderBookOnlineButton()}`}
						buttonText="Book Online"
						link="/online-booking"
						buttonClass="btn service-booking-button"
					/>
				</div>
				<button
					className={`booking-policy-modal-btn green-back white animated in-and-out ${this.toggledisplayOfBookingPolicyButton()}`}
					data-bs-toggle="modal"
					data-bs-target="#global-modal"
					onClick={this.renderBookingPolicyModal}
				>
					Booking Policy
				</button>
			</div>
		);
	}
}

const mapStateToProps = ({ url }) => {
	return { url };
};

export default connect(mapStateToProps, actions)(Header);
