import "./Footer.css";
import "../fonts/css/all.css";
import React from "react";
import PreFooter from "./PreFooter";
import BottomFooter from "./BottomFooter";

class Footer extends React.Component {
	render() {
		return (
			<div className="col-12 footer">
				<PreFooter />
				<BottomFooter />
			</div>
		);
	}
}

export default Footer;
