export const guidelinesItems = [
  {
    icon: 'comment-medical',
    title: 'Waxing Advice',
    lists: [
      {
        list: 'Get waxed every four to six weeks. When it’s done regularly,	the hair thins and comes out easier.',
      },
      {
        list: 'If skin is irritated afterward, ice for three to five minutes.',
      },
      {
        list: 'Hair ideally should be 1/8 – to 1/4inch. Anything longer will be painful, shorter might not grab.',
      },
      {
        list: 'Avoid getting waxed a week before and a week after your period, when you are most sensitive.',
      },
      {
        list: 'Don not get waxed if you have been sun tanning in the past	three to five days or exfoliated that day.',
      },
      {
        list: 'Taking antibiotics sensitizes the skin, so wait five to seven days after discontinuing antibiotics before getting waxed.',
      },
    ],
  },
  {
    icon: 'heartbeat',
    title: 'Health conditions',
    lists: [
      {
        list: 'Please advise us at the time of booking of any health conditions, allergies, injuries, recent surgeries, pregnancy	or special needs which may affect your services.',
      },
      {
        list: 'Please let your Aesthetician know if you use skin care	products that contain Retinol, Retin A.',
      },
    ],
  },
  {
    icon: 'clock',
    title: 'Appointment Policy',
    lists: [
      {
        list: 'Check in/ arrival time:',
        text: 'We ask that you please arrive 5-7 mins prior to your	appointment so you have enough time to change. We will do our	best to accommodate late arrivals.',
      },
    ],
  },
  {
    icon: 'calendar-times',
    title: 'Cancellation',
    lists: [
      {
        list: 'Your spa treatments are reserved especially for you. We value your business and ask that you respect the spa’s scheduling policies. Should you need to cancel or reschedule, please notify us at least 24 hour in advance.',
      },
    ],
  },
  {
    icon: 'thumbs-up',
    title: "Do's",
    lists: [
      {
        listClass: 'parse-into-html',
        list: `<strong> Do </strong> use sunblock after waxing if exposing skin to sun within 24hrs to avoid hyper-pigmentation.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Do </strong> leave plenty of time before Special Occasions to allow for any unforseen circumstances, breakouts, illness, etc...`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Do </strong> make us aware if using Accutane, Retin-A, Differin, Glycolic Acid, Renova, Salicylic Acid, Avage, Apricot, Scrub, Neutrogena Scrub, Avita, Tazorac, Strivectin, Pro-Active, Benzoyl Peroxide, or any other topical or oral mediactions that may affect the outcome of the waxing	treatment.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Do </strong> make us aware if you have had any problems with waxing treatments in the past.`,
      },
    ],
  },
  {
    icon: 'ban',
    title: "Don'ts",
    lists: [
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> apply moisturizer beforehand.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> expose newly waxed areas to the sun, hot tubs, sauna, suntan booths, or extreme heat for 24 hours - some skin cells have been pulled off in the process and the area is vulnerable.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> apply deodorant to newly waxed underarms or use products containing alcohol after facial waxing.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> wax during Accutane treamtment or within six (6) months after treatment.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> wax after laser Resurfacing or Chemical Peel.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> touch a newly waxed area if hands are not clean.`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> workout after waxing ( unless prior positive experience.)`,
      },
      {
        listClass: 'parse-into-html',
        list: `<strong> Don't </strong> use heavily perfumed or thick lotions in bikini area as it may cause ingrown hairs or irritated skin.`,
      },
    ],
  },
];
