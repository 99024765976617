import "./LowerBodyWaxing.css";
import _ from "lodash";
import React from "react";
import { lowerBodyWaxingItems } from "./waxingItems";
import { WaxingBlock } from "./commonWaxingComponents";
import { questionMarkModalContent } from "./questionMarkModalContent";

const { title, vector } = lowerBodyWaxingItems.logo;
const { forHer, forHim } = lowerBodyWaxingItems;

class LowerBodyWaxing extends React.Component {
	componentDidMount() {
		(function ($) {
			$(function () {
				_.forEach(
					questionMarkModalContent,
					(
						{
							questionMarkClass,
							questionMarkModalTitle,
							modalBodyPicDiv,
							serviceDescriptionParagraph,
						},
						i
					) => {
						$(`.${questionMarkClass}`).on("click", function () {
							$("#myModalTitle").html(questionMarkModalTitle);
							$(".myModalBody").html(modalBodyPicDiv);
							$(".myModalBody").append(serviceDescriptionParagraph);
						});
					}
				);
			});
		})(window.jQuery);
	}

	render() {
		return (
			<WaxingBlock
				title={title}
				vector={vector}
				arrForHer={forHer}
				arrForHim={forHim}
			/>
		);
	}
}

export default LowerBodyWaxing;
