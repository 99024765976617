import "./PreFooter.css";
import "../fonts/css/all.css";
import React from "react";
import {
	QuickLinks,
	GeneralInformation,
	HoursOfOperation,
} from "./preFooterContent";

class PreFooter extends React.Component {
	render() {
		return (
			<div className="row" id="pre-footer">
				<div className="col-12" id="contact-overlay">
					<div className="row">
						<div className="col-12 col-lg-4" id="contact">
							<div className="row">
								<QuickLinks />
								<GeneralInformation />
								<HoursOfOperation />
							</div>
						</div>

						<div className="col-12 col-lg-8" id="slogan">
							<h1>
								Beautiful
								<span className="smaller-slogan"> is how you will feel!</span>
							</h1>
						</div>

						<div className="col-12 col-lg-4 offset-lg-8 align-center white">
							Thank you for visiting!
						</div>
						<script
							type="text/javascript"
							src="https://cdn.ywxi.net/js/1.js"
							async
						></script>
					</div>
				</div>
			</div>
		);
	}
}

export default PreFooter;
