import React from "react";
import Button from "./Button";

const Banner = ({
	bannerPicClass,
	title,
	text,
	buttonDivClasses,
	buttonMessage,
	link,
	buttonClasses,
}) => {
	return (
		<div className="col-12">
			<div className="row banner-pic-main-div">
				<div className={bannerPicClass}></div>
				<div className="col-12 flex-center overlay">
					<div className="col-10">
						<h2 className="col-12 col-md-6 col-lg-4 align-center banner-pic-title">
							{title}
						</h2>
						<p className="col-12 col-md-6 col-lg-4 align-center banner-para">
							{text}
						</p>
						<Button
							divClass={buttonDivClasses}
							buttonText={buttonMessage}
							link={link}
							buttonClass={buttonClasses}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
