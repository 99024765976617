import _ from 'lodash';
import './Guidelines.css';
import '../fonts/css/all.css';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import parser from 'html-react-parser';
import { guidelinesItems } from './guidelinesItems';
import Footer from '../footer/Footer';

class Guidelines extends React.Component {
  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    this.props.setURL(window.location.pathname);
  }

  renderguidelinesItems() {
    return _.map(guidelinesItems, ({ icon, title, lists }, i) => {
      return (
        <div key={icon} className="col-12 col-lg-8 guidelines-ind-div">
          <div className="guidelines-icon-div flex-center green-back">
            <i className={`fas fa-${icon} fa-2x white`}></i>
          </div>
          <h4 className="guidelines-title align-left">{title}</h4>
          <ul className="guidelines-list">
            {_.map(lists, ({ list, text, listClass }, i) => {
              return (
                <li key={i}>
                  {listClass ? parser(list) : list}
                  {text ? <p>{text}</p> : null}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="row guidelines-main-div">
        <div className="col-12 banner-pic-main-div">
          <div className="guidelines-banner-pic"></div>
          <div className="col-12 flex-center overlay">
            <div className="col-10">
              <h2 className="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-7 align-center banner-pic-title">
                Guidelines
              </h2>
              <p className="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-7 align-center">
                In order to make your experience pleasurable and to promote
                safety, we feel it is very important to keep you informed of a
                few personal care guidelines, as well as our salon rules.
              </p>
              <div className="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-7 flex-center">
                <button
                  className="btn btn-lg announcement-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#announcement-modal"
                >
                  <i className="fas fa-bullhorn announcement-icon"></i> Schedule
                  Announcement
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 guidelines-items-div">
          <div className="container">
            <div className="row">{this.renderguidelinesItems()}</div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(null, actions)(Guidelines);
