import React from "react";
import { specialPackagesItems } from "./waxingItems";
import { WaxingBlock } from "./commonWaxingComponents";

const { title, vector } = specialPackagesItems.logo;
const { forHer, forHim } = specialPackagesItems;

class SpecialPackages extends React.Component {
	render() {
		return (
			<WaxingBlock
				title={title}
				vector={vector}
				arrForHer={forHer}
				arrForHim={forHim}
			/>
		);
	}
}

export default SpecialPackages;
