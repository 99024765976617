export const questionMarkModalContent = [
	{
		questionMarkClass: "bl",
		questionMarkModalTitle: "Bikini Line",
		modalBodyPicDiv: `<div class="description-pic bl-pic"></div>`,
		serviceDescriptionParagraph: `<p class="align-center">This form of waxing consists of waxing the bikini area and includes the sides of your legs/thighs, your panty line and below the naval. A bikini wax forms a neat inverted triangle of hair that perfectly complements the panty line of your bikini.</p>`,
	},
	{
		questionMarkClass: "frn",
		questionMarkModalTitle: "French",
		modalBodyPicDiv: `<div class="description-pic frn-pic"></div>`,
		serviceDescriptionParagraph: `<p class="align-center">French wax does not remove all the hair; it leaves a tiny bit behind. While most of your hair is removed, a strip of hair from the top to bottom is left behind.</p>`,
	},
	{
		questionMarkClass: "brz",
		questionMarkModalTitle: "Brazilian",
		modalBodyPicDiv: `<div class="description-pic brz-pic"></div>`,
		serviceDescriptionParagraph: `<p class="align-center">Sometimes referred to as the Hollywood, the Brazilian is when all hair in the pubic area is removed.</p>`,
	},
];
