export const bookingPolicyModalContent = {
	modalTitle: `
		<div class="col-12 flex-center">
			<div class="guidelines-icon-div flex-center green-back">
				<i class="fas fa-calendar-check fa-2x white"></i>
			</div>
		</div>
		<h4 class="green-modal-title align-center">Booking Policy</h4>
	`,
	modalBody: `
		<p>
			For your convenience we allow you to pre-book your appointments in
			advance.
		</p>
		<p>
			If you are booked, this means no one else can book during your reserved
			time. If you end up being a
			<span class="italic bold">no show without cancelling </span>
			your appointment within 24 hrs, this means our technicians have missed
			the opportunity to service other clients…
			<span class="bold">NOT FAIR RIGHT?</span>
		</p>
		<p class="bold">
			This is why we ask that you please cancel your appointment within 24
			hours to avoid our cancellation fee of 20% of the total service price.
		</p>
		<p class="underline">OR</p>
		<p>A credit card is required to book/hold your appointment.</p>
		<p>
			We understand schedules change and will do everything we can to
			accommodate modifications and cancellations; and we request at least 24
			hours notice when canceling or rescheduling your appointment.
		</p>
		<p>
			<span class="bold">
				Appointments cancelled within 24 hours or for which clients are a
				“No-Show” will incur a charge of 20% of the service amount.
			</span>
			We appreciate your understanding and look forward to waxing you soon!
		</p>
	`,
	descriptionPicRemoveClass: "bl-pic brz-pic frn-pic",
	serviceDescription: "",
};
