import React from "react";
import { useNavigate } from "react-router-dom";

const Button = ({ divClass, link, buttonClass, buttonText, arrow }) => {
	const navigate = useNavigate();
	return (
		<div className={divClass}>
			<button className={`btn ${buttonClass}`} onClick={() => navigate(link)}>
				{buttonText} {arrow ? <span className="arrow">&#8594;</span> : null}
			</button>
		</div>
	);
};

export default Button;
