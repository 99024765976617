import React from "react";

export const GlobalModal = () => {
	return (
		<div
			className="modal fade"
			id="global-modal"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="myModalTitle"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title full-width align-center"
							id="myModalTitle"
						>
							Global Modal
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body myModalBody">
						<div className="description-pic"></div>
						<p className="service-description align-center"></p>
					</div>
					<div className="modal-footer">
						<div className="d-grid gap-2">
							<button
								type="button"
								className="btn btn-lg btn-block"
								data-bs-dismiss="modal"
								id="send"
							>
								GOT IT
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
