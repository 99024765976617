import "./ScrollUpButton.css";
import React from "react";
import { animateScroll as scroll } from "react-scroll";

class ScrollUpButton extends React.Component {
	componentDidMount() {
		const scrollUp = document.querySelector(".scroll-up-button");

		window.addEventListener("scroll", () => {
			if (window.scrollY > window.innerHeight / 2) {
				scrollUp.classList.remove("d-none");
				scrollUp.classList.remove("fadeOutUp");
				scrollUp.classList.add("fadeInDown");
			} else {
				scrollUp.classList.remove("fadeInDown");
				scrollUp.classList.add("fadeOutUp");
			}
		});
	}

	scrollToTop = () => {
		scroll.scrollToTop({
			duration: 1500,
			delay: 50,
			smooth: "linear",
		});
	};

	render() {
		return (
			<div
				className="white-back flex-center scroll-up-button animated d-none"
				onClick={this.scrollToTop}
			>
				<i className="green fas fa-angle-double-up"></i>
			</div>
		);
	}
}

export default ScrollUpButton;
