export const contactBannerItems = [
	{
		contactNum: "1",
		link: "tel:678-718-5816",
		icon: "phone-square",
		action: "Call",
	},
	{
		contactNum: "2",
		link: "mailto:meronhaile@bellawaxingsalon.com",
		icon: "envelope",
		action: "Email",
	},
	{
		contactNum: "3",
		link:
			"https://www.google.com/maps/place/340+W+Ponce+de+Leon+Ave,+Decatur,+GA+30030,+USA/@33.775185,-84.3014236,18.9z/data=!4m5!3m4!1s0x88f5073d4821aeb5:0xbb6aa9d3d4ca34a4!8m2!3d33.77536!4d-84.301047",
		icon: "directions",
		action: "Find Us",
	},
];
