import "./Booking.css";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
// import Footer from "../footer/Footer";

class Booking extends React.Component {
	componentDidMount() {
		this.props.setURL("/online-booking");
		window.scroll({
			top: 0,
			left: 0,
			behavior: "instant",
		});

		window.TTE.init({
			targetDivId: "TTE-UUID",
			uuid: "d954fb44-285d-48f0-9628-a26a6f0f55b4",
			maxHeight: "initial",
			buttonColor: "rgba(114, 166, 3, 1)",
			buttonFontColor: "rgba(255, 255, 255, 1)",
			buttonHoverColor:
				"rgba(85.50000000000001, 124.50000000000001, 2.249999999999978, 1)",
			progressColor: "rgba(114, 166, 3, 1)",
			progressFontColor: "rgba(255, 255, 255, 1)",
			clickableProgressColor: "rgba(142.5, 207.5, 3.7499999999999583, 1)",
		});

		if (window.location.pathname === "/online-booking") {
			this.bookingWidgetDisplayToggle();
		}
	}

	bookingWidgetDisplayToggle() {
		setTimeout(this.showWidget, 3000);
	}

	showWidget() {
		document.querySelector(".loader").style.display = "none";
		document.querySelector(".booking-widget").style.display = "block";
	}

	render() {
		return (
			<div className="row booking-container">
				<div className="col-12 panel-group">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<h4 className="title">Online Booking</h4>
								<hr className="half-hr" />
							</div>
							<div className="col-12 flex-center loader-container">
								<div className="loader"></div>
							</div>
							<div className="booking-widget">
								<div id="TTE-UUID" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(null, actions)(Booking);
