import React from "react";

export const feedbackFormFields = ({
	input,
	icon,
	label,
	id,
	type,
	rows,
	meta: { error, touched },
}) => {
	return rows ? (
		<div className="form-group form-icon-div">
			<i className={`fas fa-${icon} green`}></i>
			<textarea
				className="form-control"
				id={id}
				{...input}
				placeholder={label}
				type={type}
				rows={rows}
			/>
			<small className="text-danger">{touched && error}</small>
		</div>
	) : (
		<div className="form-group form-icon-div">
			<i className={`fas fa-${icon} green`}></i>
			<input
				className="form-control"
				id={id}
				{...input}
				placeholder={label}
				type={type}
				autoComplete="off"
			/>
			<small className="text-danger">{touched && error}</small>
		</div>
	);
};
