import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

// Quick Links
export const QuickLinks = () => {
	return (
		<div className="col-12 bottom-margin">
			<h3 className="footer-title">Quick Links</h3>
			<ul className="quick-links">
				<li>
					<Link to="/">
						<i className="icon light-green fas fa-home"></i>
					</Link>
				</li>
				<li>
					<Link to="/waxing-services">Waxing services</Link>
				</li>
				<li>
					<Link to="/facial-services">Facial services</Link>
				</li>
				<li>
					<Link to="/guidelines">Guidelines</Link>
				</li>
				<li>
					<Link to="/contact-us">Contact us</Link>
				</li>
				<li>
					<Link to="/online-booking">Online booking</Link>
				</li>
			</ul>
		</div>
	);
};

// General Inforamtion
const generalInformation = [
	{
		icon: "user",
		text: "Meron Haile",
		link: "#",
	},
	{
		icon: "envelope",
		text: "meronhaile@bellawaxingsalon.com",
		link: "mailto:meronhaile@bellawaxingsalon.com",
	},
	{
		icon: "phone-square",
		text: "(678) 718 - 5816",
		link: "tel:678-718-5816",
	},
	{
		icon: "map",
		text: "340 W. Ponce De Leon Ave, Suite 202, Decatur GA, 30030",
		link: "/salon-location",
	},
];

const parseGeneralInformation = () => {
	return _.map(generalInformation, ({ icon, text, link }, i) => {
		return (
			<div key={i} className="col-12">
				<div className="row">
					<div className="col-1">
						<i className={`fas fa-${icon}`} style={{ color: "#bfd962" }}></i>
					</div>
					<div
						className={`col-11 ${i === 0 ? "owner" : ""} no-left-pad-over-xs`}
					>
						{i === 0 || i === 3 ? (
							<Link to={link} className="white">
								{text}
							</Link>
						) : (
							<a href={link} className="white">
								{text}
							</a>
						)}
					</div>
				</div>
			</div>
		);
	});
};

export const GeneralInformation = () => {
	return (
		<div className="col-12 bottom-margin">
			<h3 className="footer-title">General Information</h3>
			<div className="row">{parseGeneralInformation()}</div>
		</div>
	);
};

// Hours of operation
export const HoursOfOperation = () => {
	return (
		<div className="col-12 bottom-margin">
			<h3 className="footer-title">Hours of Operation</h3>
			<div className="row">
				<div className="col-1">
					<i className="fas fa-clock" style={{ color: "#bfd962" }}></i>
				</div>
				<div className="col-11 no-left-pad-over-xs white">
					<div className="row">
						<div className="col-12">
							Mon - Fri:<span className="hours">9:00AM - 6:00PM</span>
						</div>
						<div className="col-12">
							Sat:<span className="hours">9:00AM - 4:00PM</span>
						</div>
						<div className="col-12">
							Sun:<span className="hours">closed</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
