import _ from "lodash";
import React from "react";

// Additional info for lower body private area wax
const QuestionMark = ({ modalClass }) => {
	return (
		<span
			className={`question-mark ${modalClass}`}
			data-bs-toggle="modal"
			data-bs-target="#global-modal"
		>
			<i className="far fa-question-circle"></i>
		</span>
	);
};

// Waxing services items to be shown in For Her and For Him blocks
const WaxingItems = ({ arr }) => {
	return _.map(arr, ({ service, price, span }, i) => {
		return (
			<li key={i}>
				<div className="row">
					<div className="col-9 align-left">
						{service}
						{span ? <QuestionMark modalClass={span} /> : ""}
					</div>
					<div className="col-3 align-right">{price}</div>
				</div>
			</li>
		);
	});
};

const renderServiceBlocks = (serviceBlocks) => {
	return _.map(serviceBlocks, (serviceBlock, i) => {
		return (
			<div key={i} className="col-12 col-sm-6 service-items-container">
				<h2 className="align-center for-her">
					{i === 0 ? "For Her" : "For Him"}
				</h2>
				<div className="row">
					<div className="col-12 service-items-div">
						<ul className="no-left-pad no-margin">
							<WaxingItems arr={serviceBlock} />
						</ul>
					</div>
				</div>
			</div>
		);
	});
};

// Main component encompassing For Her and For Him blocks
export const WaxingBlock = ({ title, vector, arrForHer, arrForHim }) => {
	return (
		<div className="row">
			<div className="col-12">
				<div className="flex-center service-svg-div">{vector}</div>
				<h4 className="service-title">{title}</h4>
				<hr className="half-hr" />
			</div>
			<div className="col-12 service-list-container">
				<div className="service-list-div">
					<div className="row">
						{renderServiceBlocks([arrForHer, arrForHim])}
					</div>
				</div>
			</div>
		</div>
	);
};
